/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { getImage, getSrc } from "gatsby-plugin-image"

import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, useTitleTemplate, meta, image, title }) {
  const { site, file } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        file(relativePath: { eq: "og_images/default.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
      }
    `
  )

  const metaImage = image || getImage(file)

  const metaDescription = description || site.siteMetadata.description
  const imageSrc =
    metaImage && getSrc(metaImage)
      ? `${site.siteMetadata.siteUrl}${getSrc(metaImage)}`
      : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={
        useTitleTemplate === true ? `%s | ${site.siteMetadata.title}` : null
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: imageSrc,
                },
                {
                  property: "og:image:width",
                  content: 1200,
                },
                {
                  property: "og:image:height",
                  content: 600,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  useTitleTemplate: true,
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  useTitleTemplate: PropTypes.bool,
  image: PropTypes.object,
}

export default Seo
