import React from "react"
import { css, Global } from "@emotion/react"
import { useThemeUI } from "theme-ui"

const Preflight = (props) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
        }

        /**
       * 1. reset font styles in all browsers.
       * 2. Remove default margin and padding in Firefox and Safari.
       * 3. Remove the inheritance of text transform in Firefox.
       * 4. set cursor always to pointer
       */
        button {
          font-family: inherit; /* 1 */
          font-size: 100%; /* 1 */
          line-height: inherit; /* 1 */
          color: inherit; /* 1 */
          margin: 0; /* 2 */
          padding: 0; /* 2 */
          text-transform: none; /* 3 */
          cursor: pointer; /* 4 */
        }

        /* Removes the default spacing and border for appropriate elements. */
        blockquote,
        dl,
        dd,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        hr,
        figure,
        p,
        pre {
          margin: 0;
        }

        /* Allow adding a border to an element by just adding a border-width. */
        *,
        ::before,
        ::after {
          border-width: 0;
          border-style: solid;
        }

        /* Reset default font size and weight for headings */
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: inherit;
          font-weight: inherit;
        }

        /* Reset links to optimize for opt-in styling instead of opt-out */
        a {
          color: inherit;
          text-decoration: inherit;
        }

        /**
       * Make replaced elements display: block by default as that's
       * the behavior you want almost all of the time. Inspired by
       * CSS Remedy, with svg added as well.
       *
       * https://github.com/mozdevs/cssremedy/issues/14
       */
        img,
        svg,
        video,
        canvas,
        audio,
        iframe,
        embed,
        object {
          display: block;
          vertical-align: middle;
        }

        /**
       * Constrain images and videos to the parent width and preserve
       * their instrinsic aspect ratio.
       *
       * https://github.com/mozdevs/cssremedy/issues/14
       */
        img,
        video {
          max-width: 100%;
          height: auto;
        }

        /**
       * Prevent vertical overflow for <Breakout /> component
      */
        body {
          overflow-x: hidden;
        }

        /**
       * Focus Styles: Remove the inner border and padding in Firefox.
       */
        button::-moz-focus-inner,
        [type="button"]::-moz-focus-inner,
        [type="reset"]::-moz-focus-inner,
        [type="submit"]::-moz-focus-inner {
          border-style: none;
          padding: 0;
        }

        /**
       * Focus Styles
       */
        button:focus,
        a:focus {
          outline: 2px dashed ${theme.colors.text};
        }

        /**
       * Text selection
       */
        ::selection {
          color: ${theme.colors.black};
          background: ${theme.colors.primary};
        }
      `}
    />
  )
}

export default Preflight
