import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Icon from "./icon"
import { alpha, tint } from "@theme-ui/color"

/**
 * `IconToggle` is similar to the `<IconButton>` component, but allows you to have a selected state.
 *
 * This is handled by using html's `<input>` element and has two different
 * types: `radio` or `checkbox`. Here's a short explanation when it's
 * appropriate to use them:
 *
 * > Radio buttons are similar to checkboxes, but with an important distinction — within a group of actions only one radio button can be selected at a time, whereas checkboxes allow for multiple values to be selected. That's also reflected in the different behaviour when navigating with a keyboard.
 *
 */
const IconToggle = ({
  type,
  variant,
  icon,
  label,
  color,
  selectedColor,
  ref,
  ...restProps
}) => {
  const variants = {
    ghost: {
      p: 1,
      "input:focus ~ &, :hover, :focus": {
        bg: alpha(color, 0.1),
      },
      "input:checked ~ &": {
        color: selectedColor,
        bg: alpha(selectedColor, 0.1),
      },
    },
    plain: {
      bg: "transparent",
      p: 0,
    },
  }

  const variantStyles = variants[variant]

  const sharedStyles = {
    borderRadius: "md",
    cursor: "pointer",
    color: color,
    ":hover, :focus": {
      color: tint(color, 0.1),
    },
    "input:checked ~ &, input:checked:hover ~ &, input:checked:focus ~ &": {
      color: selectedColor,
    },
    variant: "forms.controls",
  }

  return (
    <Box as="label" display="inline-block" title={label}>
      <Box
        ref={ref}
        as="input"
        type={type}
        aria-label={label}
        {...restProps}
        sx={{
          position: "absolute",
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      />
      <Box
        aria-hidden="true"
        sx={{
          ...sharedStyles,
          ...variantStyles,
        }}
      >
        <Icon icon={icon} />
        <Box as="span" sx={{ display: "none" }}>
          {label}
        </Box>
      </Box>
    </Box>
  )
}

IconToggle.propTypes = {
  /** toggle can either work as a checkbox or radio input */
  type: PropTypes.oneOf(["checkbox", "radio"]),
  /** pass icon component which should be used */
  icon: PropTypes.node.isRequired,
  /** color for the initial state */
  color: PropTypes.string,
  /** this sets both the text of the wrapping label and the aria-label */
  label: PropTypes.string,
  /** color for the selected state */
  selectedColor: PropTypes.string,
  /** visual appearance of the input */
  variant: PropTypes.oneOf(["ghost", "plain", "contrast"]),
}

IconToggle.defaultProps = {
  color: "text",
  selectedColor: "primary",
  type: "checkbox",
  variant: "ghost",
}

export default IconToggle
