import React from "react"
import { useColorMode } from "theme-ui"

import Box from "./box"
import Inline from "./inline"
import IconToggle from "./icon-toggle"

const ThemeToggle = () => {
  const [colorMode, setColorMode] = useColorMode()

  return (
    <Box as="fieldset" sx={{ m: 0, p: 0, border: 0 }}>
      <Box as="legend" sx={{ display: "none" }}>
        Farbschema wählen
      </Box>
      <Inline space={3}>
        <IconToggle
          type="radio"
          name="theme"
          icon="theme-light"
          label="Farbschema Tag"
          defaultChecked={colorMode === "light"}
          onChange={(e) => {
            setColorMode("light")
          }}
        />
        <IconToggle
          type="radio"
          name="theme"
          icon="theme-dark"
          label="Farbschema Nacht"
          defaultChecked={colorMode === "dark"}
          onChange={(e) => {
            setColorMode("dark")
          }}
        />
      </Inline>
    </Box>
  )
}

ThemeToggle.defaultProps = {}

export default ThemeToggle
